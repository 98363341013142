import React, { Component } from 'react';
import axios from "axios";


import pestCloudAxios from "../../utils/pestCloudAxios";

// standard_l8s
// standard_f32s_v2
const VMOfferings_STORAGE=[
	{
      "name": "Standard_L4s",
      "numberOfCores": 4,
      "osDiskSizeInMB": 1047552,
      "resourceDiskSizeInMB": 694272,
      "memoryInMB": 32768,
      "maxDataDiskCount": 16
    },
    {
      "name": "Standard_L8s",
      "numberOfCores": 8,
      "osDiskSizeInMB": 1047552,
      "resourceDiskSizeInMB": 1421312,
      "memoryInMB": 65536,
      "maxDataDiskCount": 32
    },
    {
      "name": "Standard_L16s",
      "numberOfCores": 16,
      "osDiskSizeInMB": 1047552,
      "resourceDiskSizeInMB": 2874368,
      "memoryInMB": 131072,
      "maxDataDiskCount": 64
    },
    {
      "name": "Standard_L32s",
      "numberOfCores": 32,
      "osDiskSizeInMB": 1047552,
      "resourceDiskSizeInMB": 5765120,
      "memoryInMB": 262144,
      "maxDataDiskCount": 64
    }
]
const VMOfferings_STORAGE_BETTER=[
    {
      "name": "Standard_L8s_v2",
      "numberOfCores": 8,
      "osDiskSizeInMB": 1047552,
      "resourceDiskSizeInMB": 1421312,
      "memoryInMB": 65536,
      "maxDataDiskCount": 32
    },
    {
      "name": "Standard_L16s_v2",
      "numberOfCores": 16,
      "osDiskSizeInMB": 1047552,
      "resourceDiskSizeInMB": 2874368,
      "memoryInMB": 131072,
      "maxDataDiskCount": 64
    },
    {
      "name": "Standard_L32s_v2",
      "numberOfCores": 32,
      "osDiskSizeInMB": 1047552,
      "resourceDiskSizeInMB": 5765120,
      "memoryInMB": 262144,
      "maxDataDiskCount": 64
    },
    {
      "name": "Standard_L64s_v2",
      "numberOfCores": 64,
      "osDiskSizeInMB": 1047552,
      "resourceDiskSizeInMB": 5765120,
      "memoryInMB": 262144,
      "maxDataDiskCount": 64
    },
    {
      "name": "Standard_L80s_v2",
      "numberOfCores": 80,
      "osDiskSizeInMB": 1047552,
      "resourceDiskSizeInMB": 5765120,
      "memoryInMB": 262144,
      "maxDataDiskCount": 64
    }
]
const VMOfferings_BETTER=[
	{
      "name": "Standard_F2s_v2",
      "numberOfCores": 2,
      "osDiskSizeInMB": 1047552,
      "resourceDiskSizeInMB": 16384,
      "memoryInMB": 4096,
      "maxDataDiskCount": 4
    },
    {
      "name": "Standard_F4s_v2",
      "numberOfCores": 4,
      "osDiskSizeInMB": 1047552,
      "resourceDiskSizeInMB": 32768,
      "memoryInMB": 8192,
      "maxDataDiskCount": 8
    },
    {
      "name": "Standard_F8s_v2",
      "numberOfCores": 8,
      "osDiskSizeInMB": 1047552,
      "resourceDiskSizeInMB": 65536,
      "memoryInMB": 16384,
      "maxDataDiskCount": 16
    },
    {
      "name": "Standard_F16s_v2",
      "numberOfCores": 16,
      "osDiskSizeInMB": 1047552,
      "resourceDiskSizeInMB": 131072,
      "memoryInMB": 32768,
      "maxDataDiskCount": 32
    },
    {
      "name": "Standard_F32s_v2",
      "numberOfCores": 32,
      "osDiskSizeInMB": 1047552,
      "resourceDiskSizeInMB": 262144,
      "memoryInMB": 65536,
      "maxDataDiskCount": 32
    },
    {
      "name": "Standard_F48s_v2",
      "numberOfCores": 48,
      "osDiskSizeInMB": 1047552,
      "resourceDiskSizeInMB": 393216,
      "memoryInMB": 98304,
      "maxDataDiskCount": 32
    },
    {
      "name": "Standard_F64s_v2",
      "numberOfCores": 64,
      "osDiskSizeInMB": 1047552,
      "resourceDiskSizeInMB": 524288,
      "memoryInMB": 131072,
      "maxDataDiskCount": 32
    },
    {
      "name": "Standard_F72s_v2",
      "numberOfCores": 72,
      "osDiskSizeInMB": 1047552,
      "resourceDiskSizeInMB": 589824,
      "memoryInMB": 147456,
      "maxDataDiskCount": 32
    }
]
const VMOfferings=[
	{
      "name": "Standard_D1_v2",
      "numberOfCores": 1,
      "osDiskSizeInMB": 1047552,
      "resourceDiskSizeInMB": 51200,
      "memoryInMB": 3584,
      "maxDataDiskCount": 4
    },
    {
      "name": "Standard_D2_v2",
      "numberOfCores": 2,
      "osDiskSizeInMB": 1047552,
      "resourceDiskSizeInMB": 102400,
      "memoryInMB": 7168,
      "maxDataDiskCount": 8
    },
    {
      "name": "Standard_D3_v2",
      "numberOfCores": 4,
      "osDiskSizeInMB": 1047552,
      "resourceDiskSizeInMB": 204800,
      "memoryInMB": 14336,
      "maxDataDiskCount": 16
    },
    {
      "name": "Standard_D4_v2",
      "numberOfCores": 8,
      "osDiskSizeInMB": 1047552,
      "resourceDiskSizeInMB": 409600,
      "memoryInMB": 28672,
      "maxDataDiskCount": 32
    },
    {
      "name": "Standard_D5_v2",
      "numberOfCores": 16,
      "osDiskSizeInMB": 1047552,
      "resourceDiskSizeInMB": 819200,
      "memoryInMB": 57344,
      "maxDataDiskCount": 64
    }
]

const VMClassDescriptions = {
	standard:`These instances are based on either the 2.4 GHz Intel Xeon® E5-2673 v3 (Haswell - Sep 8, 2014) processor or the 2.3 GHz Intel Xeon® E5-2673 v4 (Broadwell 2016) processor. VMs offer a powerful combination of CPU, memory, and local disk for most production applications.`,
	better:`These virtual machines support 2-GiB RAM and 8 GB of local temporary storage (SSD) per vCPU(s), and are optimized for compute intensive workloads. VM is hyper-threaded and based on the Intel Xeon® Platinum 8168 (SkyLake 11 July 2017) processor, which can achieve single core Turbo clock speeds of 3.7 GHz and sustained all core Turbo as high as 3.4GHz with the Intel Turbo Boost Technology 2.0. These virtual machines are suitable for scenarios like batch processing, web servers, analytics`,
	storage:`These virtual machines are storage optimized virtual machines for low latency workloads. They offer up to 32 CPU cores, using the Intel® Xeon® processor E5-2698B v3 @2.0GHz (September 8, 2014) with 8-GiB random-access memory (RAM) per core, and from 768 GB to 6 TB of local solid state drive (SSD) disk.`,
	storage_better:`The virtual machines features high throughput, low latency, directly mapped local NVMe storage running on the AMD EPYCTM 7551 (2019) processor with an all core boost of 2.55GHz and a max boost of 3.0GHz. The VMs come in sizes from 8 to 80 vCPU(s) in a simultaneous multi-threading configuration. There is 8 GiB of memory per vCPU(s), and one 1.92TB NVMe SSD M.2 device per 8 vCPU(s), with up to 19.2TB (10x1.92TB) available on the 80 core variant.`
}

class DeploymentOptions extends Component {

    constructor(props) {
        super(props)

        let performance = 'standard'
        if(this.props.job && this.props.job.vmType && this.props.job.vmType.indexOf('Standard_F')>=0){
        	performance = 'better'
        }

        this.state = {
        	performance,
        	job:this.props.job,
        	updating:false,
        	isDirty:false
        }
}
    updateField(field, value) {
        let job = this.state.job
        job[field] = value
        this.setState({
            job,
            isDirty: true
        })
    }
    updateFields(fields, values) {
        let job = this.state.job
        fields.forEach((f, fx) => {
            job[f] = values[fx]
        })

        this.setState({
            job,
            isDirty: true
        })
    }

    update(){
    	let self=this
    	let job = this.state.job
    	job.nodeCount = +(job.nodeCount||0)
    	job.cpuPerNode = +(job.cpuPerNode||0)

    	self.setState({updating:true},()=>{
			axios.put('/api/jobs/'+job.objectId,job).then( (data) => {
				console.log(`Job updated successfully`);
				let updatedJob = data.data

				self.setState({job:updatedJob,isDirty:false,updating:false})

			}).catch((e) => {
				console.log('Update job failed, Error ',e)
				throw(e)
			// self.setState({errorMessage:e})
			});
    	})

    }

    render() {
        let self = this

        let totalCpu=(self.state.job.nodeCount||0)*(self.state.job.cpuPerNode ||0)

        let deployButton=(
			    <button type="button" disabled={totalCpu===0?true:false || !self.state.job.executableName} className={"btn btn-block btn-success"} onClick={()=>{self.props.onDeploy()}}>Deploy</button>
        )
        if(self.state.isDirty && totalCpu>0 && self.state.job.executableName){
        	deployButton=(
				    <button type="button" disabled={self.state.updating} className={"btn btn-block btn-warning"} onClick={()=>{self.update()}}>Update</button>
        	)
        }

        let performanceVms = null
        let performanceDescription = null
        switch(self.state.performance){
        	case 'standard':
        		performanceVms = VMOfferings
        		performanceDescription = VMClassDescriptions['standard']
        		break;
        	case 'better':
        		performanceVms = VMOfferings_BETTER
        		performanceDescription = VMClassDescriptions['better']
        		break;
        	case 'storage':
        		performanceVms = VMOfferings_STORAGE
        		performanceDescription = VMClassDescriptions['storage']
        		break;
        	case 'storage_better':
        		performanceVms = VMOfferings_STORAGE_BETTER
        		performanceDescription = VMClassDescriptions['storage_better']
        		break;
        		
        }

    	return (
    		<div style={{position:'relative',background:'white',width:'70vw',height:'90vh',zIndex:1000}}>

				<div className="row" style={{marginTop:'2vmin'}}>
					<div className="col-1" />
					<div className="col-sm-6">
						<h1 className="m-0 text-dark text-left">
							Deploy Job
						</h1>
					</div>{/* /.col */}							
				</div>{/* /.row */}
    			<div className="row" style={{maxHeight:'66vh',overflow:'scroll'}}>

    				<div className="col-1" />
    				<div className="col-4">
    					<h2 className="lead text-left" style={{marginTop:'2vmin'}}>
    						Title:
    					</h2>
    					<input className={"form-control"} type="text" value={self.state.job.title} onChange={(e)=>{
    						self.updateField('title',e.target.value.replace(/[^a-z0-9\ \-\.\_]/gi,'').replace('  ',' ').slice(0,40))
    					}} />
    					<h2 className="lead text-left" style={{marginTop:'2vmin'}}>
    						Billing Code:
    					</h2>
    					<input className={"form-control"} type="text" value={self.state.job.billingCode} onChange={(e)=>{
    						self.updateField('billingCode',e.target.value.replace(/[^a-z0-9\-\.\_]/gi,'').slice(0,20).toUpperCase())
    					}} />
    					<h2 className="lead text-left" style={{marginTop:'2vmin'}}>
    						Parameters:
    					</h2>
    					<input className={"form-control"} type="text" value={self.state.job.parameterCount} onChange={(e)=>{
    						self.updateField('parameterCount',e.target.value.replace(/[^a-z0-9]/gi,'').slice(0,10))
    					}} />
    					<h2 className="lead text-left" style={{marginTop:'2vmin'}}>
    						Runs:
    					</h2>
    					<input className={"form-control"} type="text" value={self.state.job.runCount} onChange={(e)=>{
    						self.updateField('runCount',e.target.value.replace(/[^a-z0-9]/gi,'').slice(0,10))
    					}} />
    					<h2 className="lead text-left" style={{marginTop:'2vmin'}}>
    						Iterations:
    					</h2>
    					<input className={"form-control"} type="text" value={self.state.job.iterationCount} onChange={(e)=>{
    						self.updateField('iterationCount',e.target.value.replace(/[^a-z0-9]/gi,'').slice(0,10))
    					}} />
    				</div>
    				<div className="col-2" />
    				<div className="col-4">
    					<h2 className="lead text-left" style={{marginTop:'2vmin'}}>
    						PEST Executable:
    					</h2>
    					<select 
    						className={"form-control"} 
    						value={self.state.job.executableName}
    						onChange={(e)=>{
    							self.updateField('executableName',e.target.value)
    						}}>
    						<option value="">Choose Executable</option>
    						<option value="pest_hp.exe">PEST_HP</option>
    						<option value="pest_hp_mkl.exe">PEST_HP (MKL)</option>
    						<option value="pestpp-da.exe">PEST++ (DA)</option>
    						<option value="pestpp-glm.exe">PEST++ (GLM)</option>
    						<option value="pestpp-ies.exe">PEST++ (IES)</option>
    						<option value="pestpp-mou.exe">PEST++ (MOU)</option>
    						<option value="pestpp-opt.exe">PEST++ (OPT)</option>
    						<option value="pestpp-sen.exe">PEST++ (SEN)</option>
    						<option value="pestpp-sqp.exe">PEST++ (SQP)</option>
    						<option value="pestpp-swp.exe">PEST++ (SWP)</option>
    					</select>
    					<h2 className="lead text-left" style={{marginTop:'2vmin'}}>
    						Performance:
    					</h2>
    					<select 
    						className={"form-control"} 
    						value={self.state.performance}
    						onChange={(e)=>{
    							self.setState({performance:e.target.value})
    						}}>
    						<option value="standard">Standard</option>
    						<option value="better">Better</option>
    						<option value="storage">Storage Optimized</option>
    						<option value="storage_better">Storage Optimized (Better)</option>
    					</select>
    					<h2 className="lead text-left" style={{marginTop:'2vmin'}}>
    						CPU per Node:
    					</h2>
    					<select 
    						className={"form-control"} 
    						value={self.state.job.vmType||''}
    						onChange={(e)=>{
    							let cpn = performanceVms.filter((o)=>{return o.name === e.target.value})[0] || {numberOfCores:1}
    							self.updateFields(['vmType','cpuPerNode'],[e.target.value,cpn.numberOfCores])
    						}}>
    						<option value="">Choose</option>
    						{performanceVms.map((o)=>{
    							return <option key={'k_'+o.name} value={o.name}>{o.numberOfCores} CPU</option>
    						})}
    					</select>
    					<h2 className="lead text-left" style={{marginTop:'2vmin'}}>
    						Node Count: ({totalCpu} total cpu)
    					</h2>
    					
    					<select 
    						className={"form-control"} 
    						value={self.state.job.nodeCount||''}
    						onChange={(e)=>{
    							self.updateField('nodeCount',e.target.value)
    						}}>
    						<option value={0}>Choose</option>
    						<option value={1}>1</option>
    						<option value={2}>2</option>
    						<option value={3}>3</option>
    						<option value={4}>4</option>
    						<option value={5}>5</option>
    						<option value={6}>6</option>
    						<option value={7}>7</option>
    						<option value={8}>8</option>
    						<option value={9}>9</option>
    						<option value={10}>10</option>
    						<option value={11}>11</option>
    						<option value={12}>12</option>
    						<option value={13}>13</option>
    						<option value={14}>14</option>
    						<option value={15}>15</option>
    						<option value={16}>16</option>
    						<option value={17}>17</option>
    						<option value={18}>18</option>
    						<option value={19}>19</option>
    						<option value={20}>20</option>
    						<option value={21}>21</option>
    						<option value={22}>22</option>
    						<option value={23}>23</option>
    						<option value={24}>24</option>
    						<option value={25}>25</option>
    						<option value={26}>26</option>
    						<option value={27}>27</option>
    						<option value={28}>28</option>
    						<option value={29}>29</option>
    						<option value={30}>30</option>
    						<option value={31}>31</option>
    						<option value={32}>32</option>
    						<option value={33}>33</option>
    						<option value={34}>34</option>
    						<option value={35}>35</option>
    						<option value={36}>36</option>
    						<option value={37}>37</option>
    						<option value={38}>38</option>
    						<option value={39}>39</option>
    						<option value={40}>40</option>
    						<option value={41}>41</option>
    						<option value={42}>42</option>
    						<option value={43}>43</option>
    						<option value={44}>44</option>
    						<option value={45}>45</option>
    						<option value={46}>46</option>
    						<option value={47}>47</option>
    						<option value={48}>48</option>
    						<option value={49}>49</option>
    						<option value={50}>50</option>
    					</select>


    					<div style={{fontSize:'.7em',marginTop:'10px'}}>
    						{performanceDescription}
    					</div>
    				</div>

    				<div className="col-1" />
    				<div className="col-1" />
    			</div>
				<div className="row" style={{position:'absolute',bottom:'10px',left:'0px',width:'100%'}}>
					<div className="col-1"/>
					<div className="col-3">
						<button type="button" className={"btn btn-block btn-primary"} onClick={()=>{self.props.close()}}>Cancel</button>
					</div>
					<div className="col-4">
					</div>
					<div className="col-3">
						{deployButton}
					</div>
				</div>
			</div>
		);
	}
}

export default DeploymentOptions;
